import { useEffect, useState } from "react";
import "./assets/css/index.css";
import "./assets/css/materialize.min.css";
import Particles from "particlesjs";
import Typed from "typed.js";
import AOS from "aos";
import "aos/dist/aos.css";
import SkillBar from "react-skillbars";
import M from "materialize-css";
import swal from "sweetalert";
import { Icon } from "@iconify/react";
import gsap from "gsap";

import Ineswim from "./assets/img/inesswim.webp";
import LSQ from "./assets/img/lsq.webp";
import Oxidia from "./assets/img/oxidia.webp";
import WallE from "./assets/img/walle.webp";
import Photo from "./assets/img/photo.webp";
import Escape from "./assets/img/escape.webp";
import Cash from "./assets/img/cash.png";
import Dice from "./assets/img/dice.webp";

var iOS = require("is-ios");

function App() {
  const [ParticleSpeed, setParticleSpeed] = useState("0.1");
  const [ParticleMax, setParticleMax] = useState("500");
  const [ParticleColor, setParticleColor] = useState([
    "white",
    "black",
    "#020222",
  ]);

  useEffect(() => {
    AOS.init();
    M.AutoInit();
  }, []);

  useEffect(() => {
    if (iOS) {
      document.getElementById("controls").classList.remove("hidden");
    }
  }, []);

  useEffect(() => {
    let mouseCursor = document.querySelector(".cursor");
    let carousel = document.getElementById("carousel");

    const isHover = (e) => e.parentElement.querySelector(":hover") === e;

    const myDiv = carousel;

    window.addEventListener("mousemove", cursor);

    let btn = document.querySelectorAll("a");

    function cursor(e) {
      document.addEventListener("mousemove", function checkHover() {
        const hovered = isHover(myDiv);
        if (hovered !== checkHover.hovered) {
          if (hovered) {
            mouseCursor.classList.add("hidden");
          } else {
            mouseCursor.classList.remove("hidden");
          }

          checkHover.hovered = hovered;
        }
      });

      gsap.to(mouseCursor, 0.4, {
        x: e.clientX,
        y: e.clientY,
      });
    }

    btn.forEach((link) => {
      link.addEventListener("mouseover", () => {
        mouseCursor.classList.add("scale-cursor");
      });

      link.addEventListener("mouseleave", () => {
        mouseCursor.classList.remove("scale-cursor");
      });
    });
  });

  useEffect(() => {
    var options = {
      strings: ["Bienvenue sur le CV Site de Logan THENARD."],
      typeSpeed: 30,
      showCursor: false,
      cursorChar: "|",
      loop: false,
      startDelay: 50,
    };

    new Typed("h1", options);
  }, []);

  document.addEventListener("scroll", (event) => {
    let scroll = window.pageYOffset;
    let nav = document.getElementById("nav");
    const edit = document.getElementById("editpage");

    if (scroll >= 200) {
      nav.classList.add("scrolledNav");
    } else {
      nav.classList.remove("scrolledNav");
    }

    if (scroll >= 700) {
      edit.style.display = "none";
    } else {
      edit.style.display = "block";
    }
  });

  useEffect(() => {
    Particles.init({
      selector: ".backgroundParticle",
      speed: ParticleSpeed,
      maxParticles: ParticleMax,
      color: ParticleColor,
    });
  }, [ParticleSpeed, ParticleMax, ParticleColor]);

  const EditParticle = () => {
    document.getElementById("editpage").classList.toggle("hidden");
  };

  const SKILLS = [
    { type: "HTML", level: 95 },
    { type: "CSS", level: 90 },
    { type: "Javascript", level: 85 },
    { type: "TypeScript", level: 60 },
    { type: "SQL", level: 75 },
    { type: "C++", level: 55 },
    { type: "PHP", level: 60 },
    { type: "NodeJS", level: 75 },
    { type: "Kotlin", level: 75 },
    { type: "Vue.js", level: 75 },
  ];

  const SKILLS_FRAMEWORK = [
    { type: "ReactJS", level: 75 },
    { type: "NestJS", level: 60 },
    { type: "React Native", level: 75 },
    { type: "TailwindCSS", level: 70 },
    { type: "MongoDB", level: 75 },
    { type: "Prisma", level: 75 },
    { type: "ExpressJS", level: 80 },
    { type: "Fastify", level: 70 },
    { type: "NextJS", level: 75 },
  ];

  const COLORS = {
    bar: "rgb(70 70 70)",
    title: {
      text: "white",
      background: "#000",
    },
  };

  const images = [LSQ, WallE, Oxidia, Ineswim, Escape, Cash];

  const SwalText = (nom) => {
    let text;
    switch (nom) {
      default:
        break;
      case "oxidia":
        text =
          "OXIDIA-CLOUD, prestataire de services professionnels (B2B) notamment sur ses offres serveurs dédiés. Mais également B2C. \n\n\n J'ai conçu et développé un site vitrine en ReactJS professionnel pour présenter des offre de serveurs dédiés à des clients B2B et B2C potentiels. Le site dispose d'une interface utilisateur conviviale et intuitive, qui permet aux visiteurs de naviguer facilement dans les différents services proposés.";
        swal({
          icon: Oxidia,
          text: text,
        });
        break;
      case "inesswim":
        text =
          "Inesswim est un faux site de vente en ligne créé pour des étudiants à des fins éducatives, dédié aux amoureuses de la plage et de la natation, offrant une large gamme de bikinis pour femmes. \n\n\n J'ai conçu et développé un algorithme de recommandation en PHP. \n\n L'algorithme de recommandation que j'ai développé en PHP pour le site de vente de bikini utilise les données de navigation et d'achat de chaque utilisateur pour recommander les produits les plus pertinents pour eux. \n\n L'algorithme commence par collecter des données sur les produits consultés et achetés par chaque utilisateur, ainsi que sur les produits similaires ou complémentaires. \n\n Ces données sont ensuite analysées pour identifier les préférences de chaque utilisateur en termes de style, de taille, de couleur, etc... \n\n Ensuite, l'algorithme utilise ces données pour générer des recommandations personnalisées pour chaque utilisateur, en suggérant des produits qui sont susceptibles de correspondre à leurs goûts et à leurs besoins. \n\n Les recommandations sont basées sur des critères tels que la similarité de produits, les tendances actuelles, la popularité des produits, etc...";
        swal({
          icon: Ineswim,
          text: text,
        });
        break;

      case "lsq":
        text =
          "Le Studio Quatre est un site de réservation de séances de sport conçu pour les entreprises et les particuliers. \n\n Notre site offre une large gamme d'activités sportives, allant des cours de pilates et de méditation aux séances de biking en groupe, de cardio et de musculation. \n\n Nous travaillons en partenariat avec des entreprises afin que leurs employés puissent bénéficier de cours de sport. Nous travaillons en partenariat avec des entreprises afin que leurs employer puissent bénéficier de cours de sport \n\n Notre objectif est de promouvoir la santé et le bien-être dans le milieu professionnel en offrant des séances de sport régulières et accessibles. \n\n\n\n Ma mission consistait à concevoir et développer un site web innovant et interactif qui permettrait aux utilisateurs de réserver des séances de sport, d'acheter des ebooks, de rejoindre des cours en visioconférence et de communiquer avec des entraîneurs et des experts de la santé grâce à un chat en ligne. \n\n Le site web a été conçu pour offrir une expérience utilisateur optimale, avec une interface intuitive et facile à naviguer, initialement développé en utilisant PHP, puis a été mis à jour en utilisant ReactJS et NodeJS pour améliorer ses performances et offrir une expérience utilisateur optimale. Les utilisateurs peuvent facilement parcourir les différentes activités proposées, voir les horaires et les disponibilités, réserver une place pour une séance de sport ou un cours en visioconférence, et acheter des ebooks en un seul clic. \n\n Les utilisateurs peuvent également communiquer directement avec des entraîneurs et des experts de la santé grâce à une fonctionnalité de chat en ligne. Cette fonctionnalité permet aux utilisateurs de poser des questions, d'obtenir des conseils et de bénéficier d'un soutien personnalisé pour atteindre leurs objectifs de remise en forme et de santé.";
        swal({
          icon: LSQ,
          text: text,
        });
        break;

      case "applsq":
        text =
          "STUDIO QUATRE est une application de réservation de séances de sport conçu pour les entreprises et les particuliers. \n\n Notre application offre une large gamme d'activités sportives, allant des cours de pilates et de méditation aux séances de biking en groupe, de cardio et de musculation. \n\n Nous travaillons en partenariat avec des entreprises afin que leurs employés puissent bénéficier de cours de sport. Nous travaillons en partenariat avec des entreprises afin que leurs employer puissent bénéficier de cours de sport \n\n Notre objectif est de promouvoir la santé et le bien-être dans le milieu professionnel en offrant des séances de sport régulières et accessibles. \n\n\n\n Ma mission consistait à concevoir et développer un application disponible pour les utilisateurs IOS et Android qui leur permettrait de réserver des séances de sport et de communiquer avec des entraîneurs et des experts de la santé grâce à un chat en ligne. \n\n L'application a été conçu pour offrir une expérience utilisateur optimale, avec une interface intuitive et facile à naviguer, et a été codé en React Native. Les utilisateurs peuvent facilement parcourir les différentes activités proposées, voir les horaires et les disponibilités, réserver une place pour une séance de sport ou un cours en visioconférence, et acheter des ebooks en un seul clic. \n\n Les utilisateurs peuvent également communiquer directement avec des entraîneurs et des experts de la santé grâce à une fonctionnalité de chat en ligne. Cette fonctionnalité permet aux utilisateurs de poser des questions, d'obtenir des conseils et de bénéficier d'un soutien personnalisé pour atteindre leurs objectifs de remise en forme et de santé.";
        swal({
          icon: LSQ,
          text: text,
        });
        break;

      case "wall-e":
        text =
          "En tant que groupe de 4 étudiants en 1ère STI2D, nous avons travaillé ensemble sur un projet innovant et stimulant : la réalisation d'un robot évitant les obstacles à l'aide d'une carte Arduino. \n\n\n Notre mission consistait à concevoir et construire un robot autonome qui pourrait naviguer dans un environnement en détectant les obstacles et en évitant les collisions. Pour cela, nous avons utilisé une variété de capteurs, tels que des capteurs de distance et des capteurs de lumière, pour permettre au robot de détecter les obstacles et de réagir en conséquence. \n\n  Nous avons travaillé en étroite collaboration tout au long du projet, en partageant les idées et les compétences, en planifiant et en organisant le travail en équipe, et en respectant les échéances et les délais. \n\nNous avons également utilisé les ressources en ligne, les forums de discussion et les tutoriels pour améliorer nos connaissances sur le sujet, afin d'optimiser la conception et le développement du robot. \n\n Enfin, nous avons présenté notre projet final devant un jury, en démontrant les fonctionnalités et les capacités du robot, ainsi que notre démarche et notre travail en équipe. \n\n ";
        swal({
          icon: WallE,
          text: text,
        });
        break;

      case "escape":
        text =
          "Bienvenue dans Echappes toi du manoir, un jeu d'horreur en JavaScript où vous devez choisir entre trois portes pour activer un événement terrifiant. \n\nLe jeu commence avec trois portes fermées devant vous. Vous ne savez pas ce qui se cache derrière chacune des portes, mais vous devez choisir l'une d'entre elles pour avancer dans le jeu.\n\nUne fois que vous avez choisi une porte, vous devrez alors affronter un événement effrayant et imprévisible. Vous pourriez vous retrouver nez à nez avec un monstre terrifiant, ou vous pourriez être plongé dans une pièce sombre et mystérieuse.\n\nIl y a trois portes dans le jeu, et chacune d'entre elles cache un événement différent. Vous devrez choisir judicieusement pour avancer dans le jeu et découvrir la fin terrifiante. \n\nLe jeu est conçu pour effrayer les joueurs avec des événements aléatoires, des bruits effrayants et une ambiance sombre. Il mettra vos nerfs à rude épreuve alors que vous explorez les différentes pièces et faites face aux différents défis qui se présentent à vous. \n\nPrêt à entrer dans l'horreur? Choisissez une porte et découvrez ce qui se cache derrière!";
        swal({
          icon: Escape,
          text: text,
        });
        break;

      case "dice":
        text =
          "Le jeu que je vous propose est un remake du jeu de hasard Dice, disponible sur le casino en ligne stake.games. Ce nouveau jeu a été développé en utilisant la technologie NextJS et l'ensemble d'outils de conception Tailwind.";
        swal({
          icon: Dice,
          text: text,
        });
        break;

      case "cash":
        text =
          "Cash est une application ainsi qu'un site en cours de développement permettant de gagner de l'argent en effectuant des sondages, fait en React Native, NextJS, Tailwind, NodeJS.";
        swal({
          icon: Cash,
          text: text,
        });
        break;
    }
  };

  useEffect(() => {
    const dot1 = document.getElementById("linedot1");
    const dot2 = document.getElementById("linedot2");
    const dot3 = document.getElementById("linedot3");
    const dot4 = document.getElementById("linedot4");
    const div = document.getElementById("textToShow");

    dot1.addEventListener("mouseover", () => {
      div.innerHTML =
        "<h2>Stage de 3ème</h2><p>Apprentisage de l'HTML, CSS durant 1 semaine.</p>";
    });

    dot2.addEventListener("mouseover", () => {
      div.innerHTML =
        "<h2>DiamPark</h2><p>Ajout de nouvelles fonctionalités innovante dans une application qui aide les personnes atteintes de la maladie de Parkinson.</p>";
    });

    dot3.addEventListener("mouseover", () => {
      div.innerHTML =
        "<h2>STUDIO QUATRE</h2><p>Site et Application de réservation de séance avec système d’authentification.</p>";
    });

    dot4.addEventListener("mouseover", () => {
      div.innerHTML =
        "<h2>Oxidia-Cloud</h2><p>Conception et développement d'interfaces utilisateur.</p>";
    });

    const navToggle = document.getElementById("nav-toggle");

    navToggle.addEventListener("click", () => {
      navToggle.classList.toggle("active");
    });
  });

  const NavMenu = () => {
    const nav = document.getElementById("menu-nav");

    nav.classList.toggle("hidden");
  };

  return (
    <>
      <header>
        <div className="cursor"></div>
        <canvas className="backgroundParticle"></canvas>
        <article className="EditThisPage" id="editthispage">
          <h2 onClick={EditParticle}>
            Modifiez l'animation de cette page web ! &rarr;
          </h2>
          <div id="editpage" className="hidden containerForEdit">
            <label htmlFor="changespeed">
              Changer la vitesse (x{ParticleSpeed})
            </label>
            <input
              id="changespeed"
              type="range"
              value={ParticleSpeed}
              onChange={(e) => setParticleSpeed(e.target.value)}
              min="0.1"
              max="2"
              step="0.1"
            />
            <label htmlFor="changeparticules">
              Changer le nombre de particules ({ParticleMax})
            </label>
            <input
              id="changeparticules"
              type="range"
              value={ParticleMax}
              onChange={(e) => setParticleMax(e.target.value)}
              min="1"
              max="800"
            />
            <label htmlFor="changecolor">
              Changer la couleur des particules
            </label>
            <input
              id="changecolor"
              type="color"
              value={ParticleColor}
              onChange={(e) => setParticleColor(e.target.value)}
            />
          </div>
        </article>

        <nav id="nav">
          <ul>
            <a href="#presentation-section">
              <li id="unvisible">Présentation</li>
            </a>
            <a href="#competences-section">
              <li id="unvisible">Compétences</li>
            </a>
            <li>Logan THENARD</li>
            <a href="#experiences-section">
              <li id="unvisible">Expériences</li>
            </a>
            <a href="#portfolio">
              <li id="unvisible">Portfolio</li>
            </a>
          </ul>
          <div className="responsivenav">
            <button onClick={NavMenu} className="nav-toggle" id="nav-toggle">
              <div></div>
              <div></div>
              <div></div>
            </button>
            <p>Logan THENARD</p>
          </div>
          <div id="menu-nav" className="hidden menu">
            <a href="#presentation-section">
              <li id="unvisible">Présentation</li>
            </a>
            <a href="#competences-section">
              <li id="unvisible">Compétences</li>
            </a>
            <a href="#experiences-section">
              <li id="unvisible">Expériences</li>
            </a>
            <a href="#portfolio">
              <li id="unvisible">Portfolio</li>
            </a>
          </div>
        </nav>
      </header>

      <main>
        <section id="presentation-section">
          <h1 id="h1"></h1>
          <article className="presentation">
            <div className="image-presentation">
              <img
                id="photo"
                src={Photo}
                width="300"
                height="500"
                alt="photoprofile"
              />
            </div>
            <div className="p-presentation">
              <h2>Présentation</h2>
              <p>
                Bonjour et bienvenue sur ma page de présentation ! Je suis un
                développeur web passionné qui cherche à faire la différence dans
                le monde. Je suis actuellement étudiant en développement web et
                également freelance web et mobile.
              </p>
              <p>
                Grâce à ma formation en développement web ainsi que mon
                autodidaxie, j'ai acquis des compétences en matière de
                conception de projets techniques, ainsi que des connaissances
                approfondies en matière de technologie et de développement
                d'applications web et mobile. J'ai également développé une
                compréhension des principes de l'interface utilisateur et de
                l'expérience utilisateur, ce qui me permet de concevoir des
                sites web qui répondent aux besoins des utilisateurs tout en
                offrant une expérience de navigation agréable et efficace.
              </p>
              <p>
                Je suis à la recherche d'une entreprise qui me permettra de
                mettre mes compétences et ma passion au service de projets
                innovants et qui ont un impact positif sur la société.
              </p>
            </div>
          </article>
        </section>

        <section data-aos="fade-down-right" id="competences-section">
          <article className="competences presentation">
            <h2 className="h2-competences">Compétences</h2>
            <h2 className="intitule-competences h2-competences">
              Langages de programmation :
            </h2>
            <SkillBar skills={SKILLS} height={40} colors={COLORS} />

            <h2 className="intitule-competences h2-competences">
              Frameworks | Libraries :
            </h2>
            <SkillBar skills={SKILLS_FRAMEWORK} height={40} colors={COLORS} />
          </article>
        </section>
        <section
          data-aos="flip-up"
          id="experiences-section"
          className="portfolio"
        >
          <h2 className="h2-experiences">Mes experiences | Parcours</h2>
          <p className="p-portfolio">
            Cliquez sur les points pour faire apparaître les expériences
          </p>
          <div id="textToShow" className="text-toShow">
            <h2>Oxidia-Cloud</h2>
            <p>Conception et développement d'interfaces utilisateur</p>
          </div>
          <div className="container">
            <div className="line">
              <article id="linedot1" className="linedot linedot1"></article>
              <article id="linedot2" className="linedot linedot2"></article>
              <article id="linedot3" className="linedot linedot3"></article>
              <article id="linedot4" className="linedot linedot4"></article>
            </div>
          </div>
        </section>

        <section data-aos="fade-left" id="portfolio" className="experiences">
          <div id="controls" className="hidden controls">
            <div className="left">&#60;&#60;</div>
            <div className="right"> &#62; &#62;</div>
          </div>
          <h2 className="somepadding h2-experiences">Mon Portfolio</h2>
          <div id="carousel" className="carousel">
            <article className="carousel-item">
              <img src={images[0]} alt="slide 1" />
              <div className="text-image">
                <h2>Le Studio Quatre</h2>
                <a href="https://lestudioquatre.com">Lien</a>
                <p onClick={() => SwalText("lsq")}>En savoir plus</p>
              </div>
            </article>
            <article className="carousel-item">
              <img src={images[5]} alt="slide 1" />
              <div className="text-image">
                <h2>Cash</h2>
                <p>En cours</p>
                <p onClick={() => SwalText("cash")}>En savoir plus</p>
              </div>
            </article>
            <article className="carousel-item">
              <img src={images[0]} alt="slide 1" />
              <div className="text-image">
                <h2>Application STUDIO QUATRE</h2>
                <a href="https://play.google.com/store/apps/details?id=com.st4">
                  Lien
                </a>
                <p onClick={() => SwalText("applsq")}>En savoir plus</p>
              </div>
            </article>
            <article className="carousel-item">
              <img src={images[1]} alt="slide 2" />
              <div className="text-image">
                <h2>Wall-E, robot évitant les obstacles</h2>
                <a
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=6xD9LAyvpng"
                  target="_blank"
                >
                  Voir la vidéo
                </a>
                <p onClick={() => SwalText("wall-e")}>En savoir plus</p>
              </div>
            </article>
            <article className="carousel-item">
              <img src={images[2]} alt="slide 3" />
              <div className="text-image">
                <h2>Oxidia-Cloud</h2>
                <a href="https://oxidia-cloud-logan-thenard.vercel.app/">
                  Lien
                </a>
                <p onClick={() => SwalText("oxidia")}>En savoir plus</p>
              </div>
            </article>
            <article className="carousel-item">
              <img src={images[3]} alt="slide 4" />
              <div className="text-image">
                <h2>InesSwim</h2>
                <p onClick={() => SwalText("inesswim")}>En savoir plus</p>
              </div>
            </article>
            <article className="carousel-item">
              <img src={Dice} alt="slide 4" />
              <div className="text-image">
                <h2>Dice Stake Remake</h2>
                <a href="https://dice-stake-thenard-logan.vercel.app/">Lien</a>
                <p onClick={() => SwalText("dice")}>En savoir plus</p>
              </div>
            </article>
            <article className="carousel-item">
              <img src={images[4]} alt="slide 5" />
              <div className="text-image">
                <h2>Echappes toi du manoir - Escape Game</h2>
                <a href="https://loganthenard.fr/escape">Lien</a>
                <p onClick={() => SwalText("escape")}>En savoir plus</p>
              </div>
            </article>
          </div>
        </section>

        <section className="credentials">
          <div className="download-cv">
            <button>
              <a href="mailto:logan.thenard.pro@gmail.com">
                <span>
                  <Icon icon="material-symbols:attach-email" />
                </span>
                logan.thenard.pro@gmail.com
              </a>
            </button>

            <button>
              <a href="/cv.pdf">
                <span>
                  <Icon icon="material-symbols:cloud-download" />
                </span>
                Télécharger mon CV
              </a>
            </button>

            <button>
              <a href="tel:0641118358">
                <span>
                  <Icon icon="bi:telephone-fill" />
                </span>
                06 41 11 83 58
              </a>
            </button>
          </div>
        </section>
      </main>
      <footer>
        <p>
          {"THENARD Logan - " +
            new Date().toISOString().split("T")[0].split("-")[0]}
        </p>
      </footer>
    </>
  );
}

export default App;
